import React, { useState } from 'react'
import styled from 'styled-components'
import Modal from 'react-modal'
import { File, MapPin, User, XCircle } from 'react-feather'

const months = ['janv', 'févr', 'mars', 'avr', 'mai', 'juin', 'juill', 'août', 'sept', 'oct', 'nov', 'déc']
const itemTypes = ['Couarôge', 'Apéro-Coop', "Fraiz'tival"]
const modalStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  },
  overlay: {
    zIndex: 4
  }
}

const Calendar = () => {
  const [current, setCurrent] = useState(null)
  const open = current !== null
  let currentDoc = null
  try { currentDoc = require('../../media/documents/' + current.docName ) }
  catch { }

  return (
    <Container>
      { news.map((item, i) => {
        const date = new Date(item.startDate)
        const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
        const month = months[date.getMonth()].toUpperCase()
        const year = date.getFullYear()
        return (
          <CalendarItem key={i} onClick={ () => setCurrent(item) }>
            <div className='date'>
              <span className='day'>{ day }</span>
              <span className='month'>{ month }</span>
              <span className='year'>{ year }</span>
            </div>
            <div className='name' title={ item.theme }>
              <span>{ itemTypes[item.type] }</span>
              <p>{ item.theme }</p>
            </div>
          </CalendarItem>
        )
      })}
      <Modal
            isOpen={ open }
            style={ modalStyles }
            onRequestClose={ () => setCurrent(null) }>
        { current && (
          <Event>
            <XCircle className='close' title='Fermer' onClick={ () => setCurrent(null) } />
            <h2>{ itemTypes[current.type] }</h2>
            <span className='date-and-location'>{ current.date }<br />{ current.location.name }</span>
            <p className='theme'>{ current.theme }</p>
            <div className='infos'>
              { currentDoc && (
                <div className='info'>
                  <File size={32} />
                  <a target='_blank' rel='noopener noreferrer' href={ currentDoc }>
                    Document
                  </a>
                </div>
              )}
              { current.location && (
                <div className='info'>
                  <MapPin size={32} />
                  { current.location.line1 }<br />
                  { current.location.line2 }
                </div>
              )}
              { current.contact && (
                <div className='info'>
                  <User size={32} />
                  { current.contact.name }<br />
                  <div className='links'>
                    <a href={ 'tel:' + current.contact.phone }>Téléphone</a>
                    <a href={ 'mailto:' + current.contact.email }>Email</a>
                  </div>
                </div>
              )}
            </div>
            
          </Event>
        ) }
      </Modal>
    </Container>
  )
}

export default Calendar

const Container = styled.div`
position: relative;
max-height: 60vh;
overflow: auto;
box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 8px 0px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 3px 3px -2px;
border-radius: 2px;
`
const Event = styled.div`
position: relative;
.close {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  &:hover {
    color: #444;
  }
}
h2 {
  margin: 0;
  font-weight: normal;
}
.date-and-location {
  font-style: italic;
  font-size: .9em;
}
.theme {
  display: block;
  max-width: 500px;
  margin: 1em auto;
  font-size: 1.5em;
  text-align: center;
  font-weight: 300;
}
.infos {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  font-size: .9em;
  .info {
    display: flex;
    flex-direction: column;
    width: 150px;
    padding: 0 8px;
    margin: 0 8px;
    svg {
      margin: 0 auto 12px auto;
    }
    .links {
      display: grid;
      grid-gap: 8px;
      grid-auto-flow: column;    
    }
  }
}
`
const CalendarItem = styled.div`
display: flex;
align-items: center;
padding: 8px;
background-color: #F4F4F4;
cursor: pointer;

&:not(:last-child) {
  border-bottom: 1px solid lightgray;
}
&:hover {
  background-color: white;
}
.date {
  display: grid;
  grid-template-columns: 20px 30px;
  grid-template-rows: auto auto;
  text-align: center;
  width: 50px;
  margin-right: 4px;

  .day {
    grid-column: 1;
    grid-row: 1 / 3;
    align-self: center;
    font-size: 20px;
  }
  .month {
    grid-column: 2;
    grid-row: 1;
    font-size: 10px;
  }
  .year {
    grid-column: 2;
    grid-row: 2;
    font-size: 10px;
  }
}
.name {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  p {
    margin: 0;
    font-size: 11px;
    line-height: 11px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
`

const news = [
  {
    "type": 1,
    "startDate": "04-09-2015 17:30",
    "endDate": "04-09-2015 20:30",
    "date": "Jeudi 9 avril 2015, 17h30 - 20h30",
    "theme": "Présentations autour de modèles de coopération économique. Témoignages et ateliers créatifs",
    "docName": "AperoCoop-090415.pdf",
    "contact": {
      "name": "Dominique RIVIÈRE",
      "address": "",
      "phone": "",
      "mobile": "06 38 92 27 89",
      "email": "d.riviere@parc-ballons-vosges.fr"
    },
    "location": {
      "name": "Au POLE ECOCONSTRUCTION DES VOSGES",
      "line1": "14, Route des Aulnes",
      "line2": "88230 FRAIZE",
      "hint": "(15 min de Saint-Dié)"
    }

  },
  {
    "type": 1,
    "startDate": "11-12-2015 17:30",
    "endDate": "11-12-2015 20:30",
    "date": "Jeudi 12 novembre 2015, 17h30 - 20h30",
    "theme": "Process, matériaux et démarches innovants. Témoignages et ateliers projets",
    "docName": "AperoCoop-121115.pdf",
    "contact": {
      "name": "Dominique RIVIÈRE",
      "address": "",
      "phone": "",
      "mobile": "06 38 92 27 89",
      "email": "d.riviere@parc-ballons-vosges.fr"
    },
    "location": {
      "name": "Au POLE ECOCONSTRUCTION DES VOSGES",
      "line1": "14, Route des Aulnes",
      "line2": "88230 FRAIZE",
      "hint": "(15 min de Saint-Dié)"
    }
  },
  {
    "type": 1,
    "startDate": "06-20-2016 17:30",
    "endDate": "06-20-2016 20:30",
    "date": "Lundi 20 juin 2016, 17h30 - 20h30",
    "theme": "Autorénovation, autoconstruction : et les pros, alors ? Retour d'exprérience et ateliers",
    "docName": "AperoCoop-200616.pdf",    
    "contact": {
      "name": "Dominique RIVIÈRE",
      "address": "",
      "phone": "",
      "mobile": "06 38 92 27 89",
      "email": "d.riviere@parc-ballons-vosges.fr"
    },
    "location": {
      "name": "Au POLE ECOCONSTRUCTION DES VOSGES",
      "line1": "14, Route des Aulnes",
      "line2": "88230 FRAIZE",
      "hint": "(15 min de Saint-Dié)"
    }
  },
  {
    "type": 1,
    "startDate": "03-22-2018 17:30",
    "endDate": "03-22-2018 20:30",
    "date": "Jeudi 22 mars 2018, 17h30 - 20h30",
    "theme": "Rénovation du bâti ancien : connaitre et éviter les risques > Mycologie et Mooc !",
    "docName": "AperoCoop-220318.pdf",    
    "contact": {
      "name": "Dominique RIVIÈRE",
      "address": "",
      "phone": "",
      "mobile": "06 38 92 27 89",
      "email": "d.riviere@parc-ballons-vosges.fr"
    },
    "location": {
      "name": "Au POLE ECOCONSTRUCTION DES VOSGES",
      "line1": "14, Route des Aulnes",
      "line2": "88230 FRAIZE",
      "hint": "(15 min de Saint-Dié)"
    }
  },
  {
    "type": 1,
    "startDate": "10-11-2018 17:30",
    "endDate": "10-11-2018 20:30",
    "date": "Jeudi 11 octobre 2018, 17h30 - 20h30",
    "theme": "Nos déchets sont vos ressources. Le réemploi des matériaux du bâtiment : une opportunité pour innover !",
    "docName": "AperoCoop-111018.pdf",
    "contact": {
      "name": "Dominique RIVIÈRE",
      "address": "",
      "phone": "",
      "mobile": "06 38 92 27 89",
      "email": "d.riviere@parc-ballons-vosges.fr"
    },
    "location": {
      "name": "Au POLE ECOCONSTRUCTION DES VOSGES",
      "line1": "14, Route des Aulnes",
      "line2": "88230 FRAIZE",
      "hint": "(15 min de Saint-Dié, covoiturage possible sur demande)"
    }
  },
  {
    "type": 0,
    "startDate": "11-27-2018 18:00",
    "endDate": "11-27-2018 21:00",
    "date": "Mardi 27 novembre 2018, 18h00 - 21h00",
    "theme": "Pathologies, humidité et perspirance des parois",
    "docName": "Couaroge-271118_171218_080119.pdf",
    "contact": {
      "name": "Xavier TIBLE, chargé de mission Habiter et Rénover Mieux",
      "address": "Pays de la Déodatie",
      "phone": "03.29.56.77.35",
      "mobile": "06.38.59.15.73",
      "email": "xtible@deodatie.com"
    },
    "location": {
      "name": "Au POLE ECOCONSTRUCTION DES VOSGES",
      "line1": "14, Route des Aulnes",
      "line2": "88230 FRAIZE",
      "hint": "(15 min de Saint-Dié, covoiturage possible sur demande)"
    }
  },
  {
    "type": 0,
    "startDate": "12-17-2018 18:00",
    "endDate": "12-17-2018 21:00",
    "date": "Mardi 17 décembre 2018, 18h00 - 21h00",
    "theme": "Isolation thermique, santé, ventilation, étanchéité à l'air",
    "docName": "Couaroge-271118_171218_080119.pdf",
    "contact": {
      "name": "Xavier TIBLE, chargé de mission Habiter et Rénover Mieux",
      "address": "Pays de la Déodatie",
      "phone": "03.29.56.77.35",
      "mobile": "06.38.59.15.73",
      "email": "xtible@deodatie.com"
    },
    "location": {
      "name": "Au POLE ECOCONSTRUCTION DES VOSGES",
      "line1": "14, Route des Aulnes",
      "line2": "88230 FRAIZE",
      "hint": "(15 min de Saint-Dié, covoiturage possible sur demande)"
    }
  },
  {
    "type": 0,
    "startDate": "01-08-2019 18:00",
    "endDate": "01-08-2019 21:00",
    "date": "Mardi 17 décembre 2018, 18h00 - 21h00",
    "theme": "Végétalisation, Gestion des eaux, Paysage",
    "docName": "Couaroge-271118_171218_080119.pdf",
    "contact": {
      "name": "Xavier TIBLE, chargé de mission Habiter et Rénover Mieux",
      "address": "Pays de la Déodatie",
      "phone": "03.29.56.77.35",
      "mobile": "06.38.59.15.73",
      "email": "xtible@deodatie.com"
    },
    "location": {
      "name": "Au POLE ECOCONSTRUCTION DES VOSGES",
      "line1": "14, Route des Aulnes",
      "line2": "88230 FRAIZE",
      "hint": "(15 min de Saint-Dié, covoiturage possible sur demande)"
    }
  },
  {
    "type": 0,
    "startDate": "02-26-2019 18:00",
    "endDate": "02-26-2019 21:00",
    "date": "Mardi 26 février 2019, 18h00 - 21h00",
    "theme": "Menuiseries extérieures",
    "docName": "Couaroge-260219_260319_070519_110619.pdf",
    "contact": {
      "name": "Xavier TIBLE, chargé de mission Habiter et Rénover Mieux",
      "address": "Pays de la Déodatie",
      "phone": "03.29.56.77.35",
      "mobile": "06.38.59.15.73",
      "email": "xtible@deodatie.com"
    },
    "location": {
      "name": "Au POLE ECOCONSTRUCTION DES VOSGES",
      "line1": "14, Route des Aulnes",
      "line2": "88230 FRAIZE",
      "hint": "(15 min de Saint-Dié, covoiturage possible sur demande)"
    }
  },
  {
    "type": 0,
    "isDefaultAddress": true,
    "startDate": "03-26-2019 18:00",
    "endDate": "03-26-2019 21:00",
    "date": "Mardi 26 mars 2019, 18h00 - 21h00",
    "theme": "Enduits terre chaux chanvre",
    "docName": "Couaroge-260219_260319_070519_110619.pdf",
    "contact": {
      "name": "Xavier TIBLE, chargé de mission Habiter et Rénover Mieux",
      "address": "Pays de la Déodatie",
      "phone": "03.29.56.77.35",
      "mobile": "06.38.59.15.73",
      "email": "xtible@deodatie.com"
    },
    "location": {
      "name": "Au POLE ECOCONSTRUCTION DES VOSGES",
      "line1": "14, Route des Aulnes",
      "line2": "88230 FRAIZE",
      "hint": "(15 min de Saint-Dié, covoiturage possible sur demande)"
    }
  },  
  {
    "type": 0,
    "startDate": "05-07-2019 18:00",
    "endDate": "05-07-2019 21:00",
    "date": "Mardi 07 mai 2019, 18h00 - 21h00",
    "theme": "Végétalisation, gestion des eaux, paysage",    
    "docName": "Couaroge-070519_110619.pdf",
    "contact": {
      "name": "Xavier TIBLE, chargé de mission Habiter et Rénover Mieux",
      "address": "Pays de la Déodatie",
      "phone": "03.29.56.77.35",
      "mobile": "06.38.59.15.73",
      "email": "xtible@deodatie.com"
    },
    "location": {
      "name": "Au POLE ECOCONSTRUCTION DES VOSGES",
      "line1": "14, Route des Aulnes",
      "line2": "88230 FRAIZE",
      "hint": "(15 min de Saint-Dié, covoiturage possible sur demande)"
    }
  },
  {
    "type": 0,
    "startDate": "06-11-2019 18:00",
    "endDate": "06-11-2019 21:00",
    "date": "Mardi 11 juin 2019, 18h00 - 21h00",
    "theme": "Tous thèmes confondus",
    "docName": "Couaroge-070519_110619.pdf",
    "contact": {
      "name": "Xavier TIBLE, chargé de mission Habiter et Rénover Mieux",
      "address": "Pays de la Déodatie",
      "phone": "03.29.56.77.35",
      "mobile": "06.38.59.15.73",
      "email": "xtible@deodatie.com"
    },
    "location": {
      "name": "Au POLE ECOCONSTRUCTION DES VOSGES",
      "line1": "14, Route des Aulnes",
      "line2": "88230 FRAIZE",
      "hint": "(15 min de Saint-Dié, covoiturage possible sur demande)"
    }
  },
  {
    "type": 2,
    "startDate": "06-16-2018 11:30",
    "endDate": "06-16-2018 17:00",
    "date": "Samedi 16 juin 2018, 11h30 - 17h00",
    "theme": "Expositions, table ronde, ateliers découverte, démonstrations",
    "docName": "Fraiztival-160618.pdf",
    "location": {
      "name": "Au POLE ECOCONSTRUCTION DES VOSGES",
      "line1": "14, Route des Aulnes",
      "line2": "88230 FRAIZE",
      "hint": "(15 min de Saint-Dié)"
    }
  }
].sort((n1, n2) => new Date(n2.startDate) - new Date(n1.startDate))