import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
const LazyImage = ({ className, src, width, height, alt, lowSrc }) => {
  const lowAlt = `${ alt } - Basse résolution`
  return (
    <LazyLoadImage
      className={ className }
      src={ src } 
      width={ width } 
      height={ height }
      alt={ alt }
      placeholder={ (
        <img 
          className={ className }
          alt={ lowAlt } 
          width={ width }
          height={ height }
          src={ lowSrc } />
      ) } />
  )
}

export default LazyImage