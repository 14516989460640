import React, { useState } from 'react'
import useTornis from 'react-use-tornis'
import styled from 'styled-components'
import MetaTag from 'react-meta-tags'

import logoImg from '../media/home-page/home-logo.jpg'
import logoHalfImg from '../media/home-page/home-logo-low.jpg'
import LazyImage from './components/LazyImage'

import persoImg from '../media/assets/perso.svg'
import arbusteImg from '../media/assets/arbuste.svg'
import brouetteImg from '../media/assets/brouette.svg'
import img1 from '../media/members/fleur-de-chaux/1-fleur-de-chaux_lq.jpg'
import img2 from '../media/home-page/1-potage-raisonne_lq.jpg'
import img3 from '../media/members/baradel-paysage/3-baradel-paysage_lq.jpg'
import img4 from '../media/home-page/3-paille_lq.jpg'
import img5 from '../media/members/ascendense-architecture/3-vue-de-nuit_lq.jpg'
import img6 from '../media/home-page/2-paille_lq.jpg'

const Home = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600)

  useTornis(({ size }) => {
    if (size.x < 600 && isMobile === false)
      setIsMobile(true)
    else if (size.x >= 600 && isMobile === true)
      setIsMobile(false)
  }, true, ['size'])

  return (
    <Main isMobile={ isMobile }>
      <MetaTag>
        <title>THE - Transition Habitat Environnement</title>
        <meta name='description' content="Le réseau T.H.E est un réseau de professionnels du bâtiment qui a pour volonté de faire de l'éco-rénovation et de préserver le bâti ancien." />
      </MetaTag>
      <div className='main-wrapper'>
        <div className='grid'>
          <div className='row'>
            <div className='col-xs4-4'>          
              <h1>Les professionnels de l'habitat<br />éco-responsable</h1>
            </div>
            <div className='col-xs4-4 col-sm12-6'>
              <p>
              Le boulversement de nos conditions de vies, 
              dû aux conséquences de l'utilisation immodérée 
              des énergies fossiles, nous oblige aujourd'hui a 
              nous adapter et à entrer en transition.
              </p>
              <p>
              Une approche résiliente et multicritère du 
              bâtiment et de sont environnement doit être faire, 
              notamment :
              </p>
              <p>
              Sur les économies d'énergie<br />
              Sur le confort et la qualité d'air<br />
              Sur l'aménagement et l'environnement proche des lieux de vie
              </p>
              <p>
              Le réseau <b>Transition Habitat Environnement</b> est né de la 
              volonté d’hommes et de femmes, passionnés par leur métier, 
              engagés autour d’un projet collectif exigeant et ambitieux 
              pour progresser ensemble, pour sen- sibiliser, former et 
              transmettre.
              </p>
              <p>
              Cela n'est possible qu'en s'appuyant sur des techniques 
              constructives innovantes, performantes, maitrisées ; avec une 
              mise en oeuvre de qualité impliquant une bonne coopération des 
              corps de métiers, et le recours à des matériaux sains et naturels.
              </p>
              <p>
              Nos entreprises se différencient par une démarche qualitative et 
              environ- nementale. Elles se distinguent d’autres structures qui 
              fonctionnent sur des objectifs économiques forcés, s'écartant 
              d'une déontologie professionnelle qualitative.
              </p>
              <p>
              T.H.E innove, dans un esprit de démocratie participative, et s’est 
              constituée en association collégiale.
              </p>
            </div>
            <div className='col-xs4-4 col-sm12-6'>
              <LazyImage 
                className='logo-img'
                src={ logoImg } 
                lowSrc={ logoHalfImg }
                width={ 397 }
                alt='THE Logo' />
            </div>
          </div>
          <div className='row'>
            <div className='col-xs4-4 col-sm8-4 col-sm12-4'>
              <img width={ '100%' } src={ img1 } alt='Réalisation Fleur de chaux' />
              <em className='text-center'>Crédit photo : Ostermann Elena & Wendlinger Antoine</em>
            </div>
            <div className='col-xs4-4 col-sm8-4 col-sm12-4'>
              <h2>Nous proposons</h2>
              <p>
              Un ensemble de professionnels avisés, en perpétuelle 
              recherche de la qualité de conception et d’exécution, 
              privilégiant l’emploi des matériaux et techniques les 
              mieux adaptés et écologiquement responsables.
              </p>
              <p>
              De soutenir l’attractivité du territoire par la création 
              d’un « pôle de compétences / d’expertise » autour de l’habitat 
              éco-responsable sur les thèmes de l’innovation, la qualité, le 
              bien-vivre, en lien avec des nombreux partenaires.
              </p>
            </div>
            <div className='col-xs4-4 col-sm8-4 col-sm8-offset-4 col-sm12-4 col-sm12-offset-0'>
              <p>
              D’organiser des formations et des échanges entre et à 
              destination des professionnels.
              </p>              
              <p>
              D’impulser une dynamique de transition autour de l’habitat : 
              organiser ou participer à des actions de sensibilisation et 
              d’appui pédagogique ou techniques, aux particuliers et 
              maîtres d’ouvrages publics /privés, pour les éclairer dans 
              leur choix, en complémentarité avec les différents dispositifs 
              d’aide à la rénovation énergétique mis en œuvre sur le 
              territoire avec la Maison de l’Habitat et de l’Energie 
              (PETR de la Déodatie).
              </p>
            </div>
          </div>
          <div className='row'>
            <div className='col-xs4-4 col-sm8-4 col-sm12-4'>
              <img width='100%' src={ img2 } alt='Potager raisonné' />
              <img width='100%' src={ img3 } alt='Composition végétal - Baradel Paysage' />
            </div> 
            
            <div className='col-xs4-4 col-sm8-4 col-sm12-4'>
              <h2>Pourquoi ce réseau ?</h2>
              <p>
              Un réseau de petites et moyennes entreprises locales 
              expérimentées et engagées dans l’approche écologique de 
              l’habitat, de la rénovation énergétique : artisans de 
              différents corps de métiers, architectes, bureaux d’étude, 
              paysagistes…
              </p>
              <p>
              Notre objectif : initier, développer, expérimenter, promouvoir 
              et mettre en œuvre en Déodatie et sur les territoires 
              limitrophes, l’écoconstruction et l’éco-rénovation, de manière 
              globale, intégrée, performante, innovante et conforme aux 
              bonnes pratiques sociales et environnementales...
              </p>
            </div>                       
            <div className='col-xs4-4 col-sm8-8 col-sm12-4'>
              <p style={{ textAlign: 'center' }}>... au service :</p>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div>
                  <img width='60px' src={ persoImg } />
                  <img width='80px' src={ brouetteImg } style={{ marginTop: '16px' }} />
                </div>
                <div style={{ padding: '0 16px', flexGrow: '1', textAlign: 'center' }}>
                  <p>Du bien être des habitants</p>
                  <p>De la pérennité et la préservation du bâti ancien et moderne du territoire</p>
                  <p>Du développement économique des entreprises volontaires et engagées dans le réseau</p>
                </div>
                <div>
                  <img width='80px' src={ arbusteImg } />
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-xs4-4'>
              <h2>Nos engagements</h2>
            </div>
            <div className='col-xs4-4 col-sm12-4'>
              <img width={ '100%' } src={ img4 } alt='Paille - Ascendense architecture' />
              <span className='big-number'>1</span>
              <p className='big-p'>
              Développer nos compétences, au service des particuliers, 
              professionnels et collectivités.
              </p>
              <p>
              Développer nos compétences, au service des particuliers, 
              professionnels et collectivités.
              </p>
              <p>
              Proposer des solutions globales et concertées entre les 
              différents interve- nants sur un chantier.
              </p>
            </div>
            <div className='col-xs4-4 col-sm12-4'>
              <img width={ '100%' } src={ img5 } alt='Paille - Ascendense architecture' />
              <span className='big-number'>2</span>
              <p className='big-p'>
              Promouvoir et développer la filière locale de 
              l’écoconstruction / rénovation, contribuer au dynamisme 
              et à l’attractivité du territoire, par la recherche de la qualité.
              </p>
              <p>
              Piloter et mettre en œuvre des actions collectives qui permettent 
              de dyna- miser l’écoconstruction et l’éco-rénovation au profit des 
              entreprises locales.
              </p>
              <p>
              Privilégier les pratiques et actions qui répondent aux principes de 
              l’écono- mie circulaire, en privilégiant notamment les circuits courts, 
              l’approvision- nement local en matériaux.
              </p>
              <p>
              Exercer une veille technique, innover et expérimenter de nouveaux
              matériaux, procédés, systèmes constructifs.
              </p>
              <p>
              Intégrer dans nos pratiques la dimension humaine en prenant en compte 
              l’individu : l’ouvrier qui réalise les travaux, le client ou l’occupant, 
              l’apprenant – chantiers participatifs, personnes en formations…
              </p>
            </div>
            <div className='col-xs4-4 col-sm12-4'>
              <img width={ '100%' } src={ img6 } alt='Paille - Ascendense architecture' />
              <span className='big-number'>3</span>
              <p className='big-p'>
              Accompagner les porteurs d’un projet de construction ou de rénovation écologique.
              </p>
              <p>
              Faire connaître les bonnes pratiques (pour une bonne qualité de prestation et des 
              matériaux utilisés)
              </p>
              <p>
              Développer le « faire soi-même », par l’accompagnement technique des particuliers.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Main>
  )
}

export default Home

const Main = styled.main`
${ p => p.isMobile && `margin-top: 70px;` }
.row:not(:first-child) {
  margin-top: 64px;
}
h1 {
  display: block;
}
h2 {
  font-size: 16pt;
  font-weight: 300;
  text-transform: uppercase;
}
.logo-img {
  display: block;
  margin: auto;
  max-width: 100%;
}
.big-number {
  display: block;
  text-align: center;
  font-size: 36pt;
  font-weight: 300;
}
.big-p {
  font-weight: 300;
  font-size: 14pt;
}
em {
  display: block;
  font-size: 12px;
  text-align: center;
}
`