import React, { useEffect, useState } from 'react'
import { Route, Switch, Link, withRouter } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import styled from 'styled-components'
import useTornis from 'react-use-tornis'

import backgroundImg from '../media/layout/background.svg'

import accueilImg from '../media/layout/1-accueil.svg'
import actusImg from '../media/layout/2-actus.svg'
import membresImg from '../media/layout/3-membres.svg'
import contactImg from '../media/layout/4-contact.svg'

import accueilLowImg from '../media/layout/1-accueil.png'
import actusLowImg from '../media/layout/2-actus.png'
import membresLowImg from '../media/layout/3-membres.png'
import contactLowImg from '../media/layout/4-contact.png'

import navAccueil from '../media/layout/nav-1-accueil.svg'
import navActus from '../media/layout/nav-2-actus.svg'
import navMembres from '../media/layout/nav-3-membres.svg'
import navContact from '../media/layout/nav-4-contact.svg'

import Logo from './components/Logo'
import Home from './Home'
import News from './News'
import Members from './Members'
import Contact from './Contact'

window.previousSub = window.location.pathname.split('/').length === 3

const Layout = ({ location }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600)
  const animEnter = window.previousSub === true ? false : true
  const animExit = true
  window.previousSub = location.state !== undefined && location.state.sub === true

  useTornis(({ size }) => {
    if (size.x < 600 && isMobile === false)
      setIsMobile(true)
    else if (size.x >= 600 && isMobile === true)
      setIsMobile(false)
  }, true, ['size'])

  useEffect(() => {    
    window.scrollTo(0, 0)
  }, [location])

  let currentPage
  if (location.state === undefined) {
    const { pathname } = location
    if (pathname.startsWith('/actus'))        currentPage = 1
    else if (pathname.startsWith('/membres')) currentPage = 2
    else if (pathname.startsWith('/contact')) currentPage = 3
    else                                      currentPage = 0
  } else {
    currentPage = location.state.currentPage
  }
  
  return (
    <div>
      <Logo isMobile={ isMobile } />
      <Background currentPage={ currentPage }>
        <img src={ backgroundImg } alt='Montagnes en arrière plan' />
      </Background>

      <div>
        <TransitionGroup>
          <CSSTransition
            key={ location.key }
            timeout={ location.state && location.state.sub === true ? 250 : 5000 }
            enter={ animEnter }
            exit={ animExit }
            classNames={ 'switch' }>
            <Switch location={ location }>
              <Route exact path='/' component={ Home } />
              <Route path='/actus' component={ News } />
              <Route path='/membres' component={ Members } />
              <Route path='/contact' component={ Contact } />
            </Switch>
          </CSSTransition>
        </TransitionGroup>
      </div>

      <Foreground currentPage={ currentPage }>
        <div className='images'>
          <LazyLoadImage 
            alt='Premier plan - Accueil' 
            src={ accueilImg }
            placeholderSrc={ accueilLowImg } />
          <LazyLoadImage 
            alt='Premier plan - Actualités' 
            src={ actusImg }
            placeholderSrc={ actusLowImg } />
          <LazyLoadImage 
            alt='Premier plan - Membres'
            src={ membresImg }
            placeholderSrc={ membresLowImg } />
          <LazyLoadImage
            alt='Premier plan - Contact'
            src={ contactImg }
            placeholderSrc={ contactLowImg } />
        </div>
      </Foreground>

      <WoodSigns isMobile={ isMobile }>
        <WoodSign info={{ currentPage, current: 0 }}>
          <img alt='Panneau en bois de la page accueil' src={ navAccueil } />
          <div className='nav-links'>   
            <Link className='link-0-1' to={{ pathname: '/actus', state: { currentPage: 1 } } }>Actus</Link>
            <Link className='link-0-2' to={{ pathname: '/membres', state: { currentPage: 2 } } }>Membres</Link>
            <Link className='link-0-3' to={{ pathname: '/contact', state: { currentPage: 3 } } }>Contact</Link>
          </div>
        </WoodSign>
        <WoodSign info={{ currentPage, current: 1 }}>
          <img alt='Panneau en bois de la page actus' src={ navActus } />
          <div className='nav-links'>   
            <Link className='link-1-1' to={{ pathname: '/', state: { currentPage: 0 } } }>Accueil</Link>
            <Link className='link-1-2' to={{ pathname: '/membres', state: { currentPage: 2 } } }>Membres</Link>
            <Link className='link-1-3' to={{ pathname: '/contact', state: { currentPage: 3 } } }>Contact</Link>
          </div>
        </WoodSign>
        <WoodSign info={{ currentPage, current: 2 }}>
          <img alt='Panneau en bois de la page membres' src={ navMembres } />
          <div className='nav-links'>   
            <Link className='link-2-1' to={{ pathname: '/', state: { currentPage: 0 } } }>Accueil</Link>
            <Link className='link-2-2' to={{ pathname: '/actus', state: { currentPage: 1 } } }>Actus</Link>
            <Link className='link-2-3' to={{ pathname: '/contact', state: { currentPage: 3 } } }>Contact</Link>
          </div>
        </WoodSign>
        <WoodSign info={{ currentPage, current: 3 }}>
          <img alt='Panneau en bois de la page contact' src={ navContact } />          
          <div className='nav-links'>   
            <Link className='link-3-1' to={{ pathname: '/', state: { currentPage: 0 } } }>Accueil</Link>
            <Link className='link-3-2' to={{ pathname: '/actus', state: { currentPage: 1 } } }>Actus</Link>
            <Link className='link-3-3' to={{ pathname: '/membres', state: { currentPage: 2 } } }>Membres</Link>
          </div>
        </WoodSign>
      </WoodSigns>
    </div>
  )
}

export default withRouter(Layout)

const Background = styled.div`
position: fixed;
z-index: 1;
bottom: 0; 
width: 1920px;
height: 600px;
max-width: 100vw;
max-height: calc(31.25 * 1vw);
overflow: hidden;
pointer-events: none;
opacity: .5;

img {
  width: 3840px;
  max-width: 200vw;
  transition: transform 5000ms ease;
  transform: translateX(${ p => p.currentPage * -100 / 4}vw);
}
`
const Foreground = styled.div`
position: fixed;
z-index: 3;
bottom: 0; 
width: 1920px;
height: 600px;
max-width: 100vw;
max-height: calc(31.25 * 1vw);
overflow: hidden;
pointer-events: none;

.images {
  width: 7680px;
  max-width: 400vw;
  transition: transform 5000ms ease;
  transform: translateX(${ p => p.currentPage * -100}vw);

  span {
    width: 1920px;
    height: 600px;
    max-width: 100vw;
    max-height: calc(31.25 * 1vw);
    img {
      display: inline-block;
      max-width: 100vw;
      max-height: calc(31.25 * 1vw);
      flex-grow: 0;
    }
  }
}
`
const WoodSigns = styled.div`
position: fixed;
width: 86px;
height: 150px;
left: calc(100vw - 100px);
bottom: -20px;
z-index: 4;
${ p => p.isMobile === false && `
left: calc(90vw - 100px);
transform: scale(1.3);
` }
`
const WoodSign = styled.div`
position: absolute;
top: 0;
left: 0;
transform-origin: bottom center;

${ p => {
  const isActive = p.info.currentPage === p.info.current
  const isBefore = p.info.currentPage > p.info.current
  let result = ''
  if (isActive === true)      result = 'transform: rotate(0deg); opacity: 1;'
  else if (isBefore === true) result = 'transform: rotate(-180deg); opacity: 0;'
  else                        result = 'transform: rotate(180deg); opacity: 0;'

  if (isActive === true)      result += 'transition: transform 3000ms ease, opacity 0ms ease 0ms;'
  else                        result += 'transition: transform 3000ms ease, opacity 0ms ease 3000ms;'

  return result
} }

img {
  width: 86px;
  height: 150px;
}
.nav-links {
  position: absolute;
  top: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: .8em;

  a {
    display: block;
    color: white;
    text-transform: uppercase;
    text-decoration: none;
    height: 27px;

    &:hover {
      text-decoration: underline;
    }

    &.link-0-1 { transform: rotate(-4deg); padding-left: 18px;  padding-top: 3px; }
    &.link-0-2 { transform: rotate(-3deg); padding-left: 9px;  padding-top: 3px; }
    &.link-0-3 { transform: rotate(1deg) ; padding-left: 9px;  padding-top: 3px; }

    &.link-1-1 { transform: rotate(-0deg); padding-left: 20px; padding-top: 3px; }
    &.link-1-2 { transform: rotate(-2deg); padding-left: 10px;  padding-top: 0px; }
    &.link-1-3 { transform: rotate(2deg) ; padding-left: 10px;  padding-top: 2px; }
    
    &.link-2-1 { transform: rotate(-0deg); padding-left: 27px; padding-top: 0px; }
    &.link-2-2 { transform: rotate(-3deg); padding-left: 25px; padding-top: 0px; }
    &.link-2-3 { transform: rotate(2deg) ; padding-left: 12px;  padding-top: 1px; }
    
    &.link-3-1 { transform: rotate(1deg);  padding-left: 27px; padding-top: 0px; }
    &.link-3-2 { transform: rotate(-4deg); padding-left: 30px; padding-top: 0px; }
    &.link-3-3 { transform: rotate(-3deg); padding-left: 23px; padding-top: 2px; }
  }
}
`