import React, { useState, useCallback } from 'react'
import useTornis from 'react-use-tornis'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { X } from 'react-feather'
import MetaTag from 'react-meta-tags'

// 300x150 ratio
import couarogeImg from '../media/news-page/couaroge_lq.jpg'
import aperocoopImg from '../media/news-page/apero-coop_lq.jpg'
import fraiztivalImg from '../media/news-page/fraiz-tival_lq.jpg'
import Calendar from './components/Calendar'

const couarogeImgs = [
  { className: 'col-img', src: require('../media/news-page/couaroge/1-couaroge_lq.jpg'), title: 'Rénovation - Ascendense', alt: 'Rénovation - Ascendense' },
  { className: 'col-img', src: require('../media/news-page/couaroge/2-couaroge_lq.jpg'), title: 'Ouate de cellulose insufflée - Ascendense', alt: 'Ouate de cellulose insufflée - Ascendense', },
  { className: 'col-img', src: require('../media/news-page/couaroge/3-couaroge_lq.jpg'), title: 'Rénovation - Ascendense', alt: 'Rénovation - Ascendense' },
]
const aperocoopImgs = [
  { className: 'col-img', src: require('../media/news-page/apero-coop/1-apero-coop_lq.jpg'), title: 'Réunion "Apéro-Coop"', alt: 'Réunion "Apéro-Coop"' },
  { className: 'col-img', src: require('../media/news-page/apero-coop/2-apero-coop_lq.png'), title: 'Façade', alt: 'Façade' },
  { className: 'col-img', src: require('../media/news-page/apero-coop/3-apero-coop_lq.jpg'), title: 'Forêt ', alt: 'Forêt' },
  { className: 'col-img', src: require('../media/news-page/apero-coop/4-apero-coop_lq.jpg'), title: 'Réunion "Apéro-Coop"', alt: 'Réunion "Apéro-Coop"' },
]
const fraiztivalImgs = [
  { className: 'col-img', src: require('../media/news-page/fraiz-tival/2-fraiz-tival_lq.jpg'), title: 'Atelier isolation paille', alt: 'Atelier isolation paille' },
  { className: 'col-img', src: require('../media/news-page/fraiz-tival/3-fraiz-tival_lq.jpg'), title: 'Atelier enduis chaux - Fleur de chaux', alt: 'Atelier enduis chaux - Fleur de chaux' },
  { className: 'col-img', src: require('../media/news-page/fraiz-tival/1-fraiz-tival_lq.jpg'), title: "Un échassier au Fraiz'tival", alt: "Un échassier au Fraiz'tival" },
  { className: 'col-img', src: require('../media/news-page/fraiz-tival/4-fraiz-tival_lq.jpg'), title: "Un concert au Fraiz'tival", alt: "Un concert au Fraiz'tival" },
  { className: 'col-img', src: require('../media/news-page/fraiz-tival/5-fraiz-tival_lq.jpg'), title: 'Atelier enduis chaux - Fleur de chaux', alt: 'Atelier enduis chaux - Fleur de chaux' },
]

const News = ({ location }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600)

  useTornis(({ size }) => {
    if (size.x < 600 && isMobile === false)
      setIsMobile(true)
    else if (size.x >= 600 && isMobile === true)
      setIsMobile(false)
  }, true, ['size'])

  const props = { isMobile }

  switch (location.pathname) {
    case '/actus/couaroges': return <Couaroges {...props} />
    case '/actus/apero-coop': return <AperoCoop {...props} />
    case '/actus/fraiz-tival': return <FraizTival {...props} />
    case '/actus':
    default: return <MainNews isMobile={ isMobile } />
  }
}

export default News

const MainNews = ({ isMobile }) => {
  const history = useHistory()

  return (
    <Main isMobile={ isMobile }>
      <MetaTag>
        <title>THE - Actualités</title>
        <meta name='description' content="Calendrier des actualités et actions de l'association THE." />
      </MetaTag>
      <div className='main-wrapper'>
        <div className='grid'>
          <div className='row'>
            <div className='col-xs4-4'>
              <h1>Actualités de l'association</h1>
            </div>
            <div className='col-xs4-4 col-xs8-4 col-sm12-3'>
              <div className='news-card' onClick={ () => history.push('/actus/couaroges', { currentPage: 1, sub: true }) }>
                <img src={ couarogeImg } alt='Réunion "Couarôge"' />
                <div className='card-body'>
                  <h2>Couarôges</h2>
                  <p>« Nom masculin vosgien : qui est un moment sympa sans containtes, lors duquel on taille une bavette. »</p>
                </div>
              </div>
            </div>
            <div className='col-xs4-4 col-xs8-4 col-sm12-3'>
              <div className='news-card' onClick={ () => history.push('/actus/fraiz-tival', { currentPage: 1, sub: true }) }>
                <img src={ fraiztivalImg } alt="Vasque présentée lors du fraiz'tival" />
                <div className='card-body'>
                  <h2>Fraiz'tival</h2>
                  <p>« Un temps de découverte, d'échanges et de pratique, en direct avec les professionnels, les associations et organismes partenaires, convivial et ludique, pour toute la famille. »</p>
                </div>
              </div>
            </div>
            <div className='col-xs4-4 col-xs8-4 col-sm12-3'>
              <div className='news-card' onClick={ () => history.push('/actus/apero-coop', { currentPage: 1, sub: true }) }>
                <img src={ aperocoopImg } alt='Réunion Apéro-Coop'/>
                <div className='card-body'>
                  <h2>Apéro-coop</h2>
                  <p>« Une réelle occasion de rencontrer d'autres sphères et partenaires en écangeant idées, projets, besoins et adresses autour d'un sujet qui réunit et interroge. »</p>
                </div>
              </div>
            </div>
            <div className='col-xs4-4 col-xs8-8 col-sm12-3'>
              <Calendar />
            </div>
          </div>
        </div>
      </div>
    </Main>
  )
}

const Couaroges = ({ isMobile }) => (
  <Main className='short' isMobile={ isMobile }>
    <MetaTag>    
      <title>THE - Couarôges</title>
      <meta name='description' content="Présentation des couarôges de la rénovation." />
    </MetaTag>
    <div className='main-wrapper'>
      <Link className='close-btn' to={{ pathname: '/actus', state: { currentPage: 1 } } }><X /></Link>
      <div className='grid'>
        <div className='row'>
          <div className='col-xs4-4'>
            <h1>Couarôges</h1>
          </div>
        </div>
        <div className='row intro-row'>
          <div className='col-xs4-4 col-sm8-4'>
            <div className='news-intro'>
              <p>
              Il s'agit d'une simple table ronde autour de laquelle 
              des professionnels du bâtiment (architectes, ingénieurs et 
              artisans de l'association THE) échangent librement avec des 
              personnes qui s'interrogent dans le cadre d'un projet de 
              réhabilitation ou d'écoconstruction qu'ils ont à porter. 
              Côté alsacien, on appelle ça une stammtish !
              </p>
            </div>
          </div>
          <div className='col-xs4-4 col-sm8-4'>
            <img className='col-img' src={ couarogeImg } title='Réunion "Couarôge"' alt='Réunion "Couarôge"' />
          </div>
        </div>
        <div className='row grid-row'>
          <div className='col-xs4-4 col-sm12-4 col-sm12-offset-4'>
            <h2>À qui s'addresse un couarôge ?</h2>
            <p>
            A tout le monde ! Prioritairement à toute personne ayant besoin 
            de conseils et de connaissances relatifs à un projet de travaux, 
            mais aussi à des professionnels du bâtiment qui souhaitent 
            rencontrer les membres de l'association THE et partager leur 
            expérience de l'écocontruction.
            </p>
          </div>
        </div>
        <div className='row grid-row'>          
          <div className='col-xs4-4 col-sm12-4'>
            <h2>Qui anime un couarôge ?</h2>
            <p>
            Un membre de l'association THE alimente les échanges et organise
            les prises de paroles, mais les réunions sont toujours 
            co-animées par tous les participants, professionnels ou non. 
            Ils en sont les principaux acteurs et concernés.
            </p>
          </div>
          <div className='col-xs4-4 col-sm12-4'>
            <img { ...couarogeImgs[0] } />
          </div>
          <div className='col-xs4-4 col-sm12-4'>
            <h2>Comment s'organise un couarôge ?</h2>
            <p>
            Il suffit de s'inscrire, d'être à l'heure, d'apporter un peu à 
            boire et à manger pour partager et pour augmenter le degré de 
            convivialité du moment !
            </p>
          </div>
          <div className='col-xs4-4 col-sm12-4'>
            <img { ...couarogeImgs[1] } />
          </div>
          <div className='col-xs4-4 col-sm12-4'>
            <h2>Quel intérêt à s'inscrire dans un couaroge ?</h2>
            <p>
            Pour rencontrer facilement des professionnels qui s'inscrivent 
            dans la promotion active et le partage des objectifs de 
            l'éco-construction, pour mieux en comprendre certains principes 
            généraux, pour sa culture générale et pour partager un moment 
            inoubliable en parfaite détente !
            </p>
          </div>          
          <div className='col-xs4-4 col-sm12-4'>
            <img { ...couarogeImgs[2] } />
          </div>
        </div>
      </div>
    </div>
  </Main>
)
const AperoCoop = ({ isMobile }) => (
  <Main className='short' isMobile={ isMobile }>
    <MetaTag>    
      <title>THE - Apéro-Coop</title>
      <meta name='description' content="Présentation de l'événement Apéro-Coop." />
    </MetaTag>
    <div className='main-wrapper'>
      <Link className='close-btn' to={{ pathname: '/actus', state: { currentPage: 1 } } }><X /></Link>
      <div className='grid'>
        <div className='row'>
          <div className='col-xs4-4'>
            <h1>Apéro-Coop</h1>
          </div>
        </div>
        <div className='row intro-row'>
          <div className='col-xs4-4 col-sm8-4 news-intro'>
            <p>
            Les Apéro-Coop ont pour objectif de mobiliser un public très large 
            d’acteurs de la filière autour de thèmes innovants, de 
            témoignages de démarches, dispositifs ou expériences qui 
            dépassent les cadres habituels et invitent les professionnels 
            à s’ouvrir à de nouvelles pratiques, expériences et idées...
            </p>
          </div>
          <div className='col-xs4-4 col-sm8-4'>
            <img className='col-img' src={ aperocoopImg } alt='Réunion Apéro-Coop' title='Réunion Apéro-Coop' />
          </div>
        </div>
        <div className='row grid-row'>
          <div className='col-xs4-4 col-sm12-4'>
            <img { ...aperocoopImgs[1] } />
          </div>
          <div className='col-xs4-4 col-sm12-4'>
            <h2>Qui anime un apéro-coop ?</h2>
            <p>
            Un membre de l'association THE alimente les échanges et organise 
            les prises de paroles, mais les réunions sont toujours co-animées 
            par tous les participants, professionnels ou non. Ils en sont les 
            principaux acteurs et concernés.
            </p>
          </div>
          <div className='col-xs4-4 col-sm12-4'>
            <img { ...aperocoopImgs[2] } />
          </div>
          <div className='col-xs4-4 col-sm12-4'>
            <h2>Comment s'organise un apéro-coop ?</h2>
            <p>
            Les Apéro-coop se dont organisés au rythme de 2 ou 3 fois par an. 
            La session se déroule en 2 temps : un temps d'inspiration 
            (table ronde, témoignage); un temps d'expressionnen ateliers pour 
            prolonger la réflexion et imaginer d'autres possibles... Entre les 
            deux : convivialité et échanges informels autour d'un buffet !
            </p>
          </div>   
          <div className='col-xs4-4 col-sm12-4'>
            <img { ...aperocoopImgs[0] } />
          </div>       
          <div className='col-xs4-4 col-sm12-4'>
            <h2>Quel intérêt à participer à un apéro-coop ?</h2>
            <p>
            Les « Apéro-coop » ont été mis en place dès avril 2015 par Ecooparc, 
            pour favoriser, par la rencontre de professionnels, d'élus, d'associations 
            et de réseaux d'appui, l’émergence d’idées et de nouveaux projets autour 
            de l'éco-construction et l'éco-rénovation, sur le territoire déodatien.
            </p>
          </div>
        </div>
      </div>
    </div>
  </Main>
)
const FraizTival = ({ isMobile }) => (
  <Main className='short' isMobile={ isMobile }>
    <MetaTag>    
      <title>THE - Fraiz'tival</title>
      <meta name='description' content="Présentation de l'événement Fraiz'tival." />
    </MetaTag>
    <div className='main-wrapper'>
      <Link className='close-btn' to={{ pathname: '/actus', state: { currentPage: 1 } } }><X /></Link>
      <div className='grid'>
        <div className='row'>
          <div className='col-xs4-4'>
            <h1>Fraiz'tival</h1>
          </div>
        </div>
        <div className='row intro-row'>
          <div className='col-xs4-4 col-sm8-4'>
            <div className='news-intro'>
              <p>
                L'Association THE a organisé son premier "petit" Fraiz'tival en juin 2018.<br />
                La matinée a permis d'accueillir des élus locaux et de partager avec eux 
                les besoins de développement des filières locales de l'éco-construction et 
                de l'éco-rénovation. En effet, elles sont des réponses globales aux enjeux 
                de demain et portent des valeurs qui paraissent aujourd'hui prioritaires 
                dans tous les territoires et ce au bénéfice de tous.
              </p>
            </div>
          </div>
          <div className='col-xs4-4 col-sm8-4'>
            <img className='col-img' src={ fraiztivalImg } alt="Vasque présentée lors du fraiz'tival - Fleur de chaux" title="Vasque présentée lors du fraiz'tival - Fleur de chaux" />
          </div>
        </div>
        <div className='row grid-row'>
          <div className='col-xs4-4 col-sm12-4'>
            <img { ...fraiztivalImgs[0] } />
          </div>
          <div className='col-xs4-4 col-sm12-4'>
            <p>
              Le reste de la journée  a permis d’accueillir le grand public, autour de 
              la découverte des activités des membres de l'association, d’ateliers de 
              démonstration et de pratique (enduits, isolants projetés, étanchéité à 
              l'air, construction paille, conférences, rénovation de meubles avec 
              l'association Emmaus et L'Heureux Cycleur etc.).
            </p>            
          </div>
          <div className='col-xs4-4 col-sm12-4'>
            <img { ...fraiztivalImgs[1] } />
          </div>
          <div className='col-xs4-4 col-sm12-4'>
            <p>
              Nous avons également accueillis des associations partenaires et porteuses 
              des  messages de sensibilisation aux éco-gestes (Etc Terra, Ter'O, Maisons 
              Paysannes des Vosges, la Chambre des Métriers et de l'Artisanat, Lorraine 
              Qualité Environnement), tout ça dans l'ambiance du fantastique site du PECV 
              (visitable par immersion en drone grâce à Drône Ambition) et l'ambiance 
              d'une animation musicale (groupe Doctor Kiks) et de cirque (Mimouch Compagnie) 
              accompagnés de collations de grande qualité (L'entre 2 pains et Pizza Guizz).
            </p>
            <p><i>Merci à tous, nous remettrons les couverts en 2020 pour le grand Fraiz'Tival!</i></p>
          </div>
          <div className='col-xs4-4 col-sm12-4'>
            <img { ...fraiztivalImgs[2] } />
          </div>
          <div className='col-xs-4-4 col-sm12-4'>
            <h2>À qui s'addresse le Fraiz’tival ?</h2>
            <p>
              Le Fraiz’tival permet d'accueillir des élus locaux et de partager
              avec eux les besoins de développement des filières locales de
              l'éco-construction et de l'éco-rénovation. En effet, elles sont des
              réponses globales aux enjeux de demain et portent des valeurs qui
              paraissent aujourd'hui prioritaires dans tous les territoires et
              ce au bénéfice de tous. Le reste de la journée a permis
              d’accueillir le grand public, autour de la découverte des
              activités des membres de l'association.
            </p>
          </div>
          <div className='col-xs4-4 col-sm12-4'>
            <img { ...fraiztivalImgs[3] } />
          </div>
          <div className='col-xs4-4 col-sm12-4 col-sm12-offset-4'>
            <img { ...fraiztivalImgs[4] }/>
          </div>
        </div>
      </div>
    </div>
  </Main>
)

const Main = styled.main`
${ p => p.isMobile && `margin-top: 70px;` }
img.col-img {
  width: 100%;
}
.row.intro-row {
  margin-bottom: 64px;
}
.row.grid-row {
  h2 {
    margin-top: 0;
  }
}

.news-intro {
  font-size: 20px;
  font-weight: 300;
  font-style: italic;

  p {
    margin-top: 0;
  }

  ul {
    list-style-type: none;
    margin-top: 0;
    padding: 0;
  }
}

.news-card {
  margin-bottom: 32px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 8px 0px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 3px 3px -2px;
  border-radius: 2px;
  overflow: hidden;
  background-color: #F4F4F4;
  cursor: pointer;
  transition: box-shadow 250ms ease, background-color 250ms ease;

  &:hover {
    background-color: white;
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
  }

  img {
    width: 100%;
  }
  .card-body {
    padding: 8px;
    
    h2 {
      text-transform: uppercase;
      font-weight: 300;
      margin: 8px 0 16px 0;
    }

    a {
      display: block;
      margin-left: auto;
      padding: 8px 16px;
      width: 52px;
      text-transform: uppercase;
      text-align: center;
      text-decoration: none;
      border-radius: 3px;
      background-color: rgba(0,0,0,0);
      transition: background-color 250ms ease;

      &:hover {
        background-color: rgba(0,0,0,0.1);
      }
    }
  }
}
`