import React, { useCallback, useState } from 'react'
import useTornis from 'react-use-tornis'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { X } from 'react-feather'
import Gallery from 'react-photo-gallery'
import Carousel, { Modal, ModalGateway } from 'react-images'
import MetaTag from 'react-meta-tags'

import MemberCard from './components/MemberCard'

const Members = ({ location }) => {
  const member = members.filter(m => '/membres/' + m.url === location.pathname)[0]
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600)

  useTornis(({ size }) => {
    if (size.x < 600 && isMobile === false)
      setIsMobile(true)
    else if (size.x >= 600 && isMobile === true)
      setIsMobile(false)
  }, true, ['size'])

  if (member !== undefined && member !== null) {
    return <Member member={ member } isMobile={ isMobile } />
  } else {
    return <MainMembers isMobile={ isMobile } />
  }
}

export default Members

const MainMembers = ({ isMobile }) => {
  return (
    <Main isMobile={ isMobile }>
      <MetaTag>    
        <title>THE - Membres</title>
        <meta name='description' content="Liste des membres de l'association." />
      </MetaTag>
      <div className='main-wrapper'>
        <div className='grid'>
          <div className='row'>
            <div className='col-xs4-4'>
              <h1>Les membres de T.H.E.</h1>
            </div>
            { members.map(member => (
              <div key={ member.name } className='col-xs4-4 col-md12-6'>
                <MemberCard member={ member } />
              </div>
            ))}            
          </div>
        </div>
      </div>
    </Main>
  )
}

const Member = ({ member, isMobile }) => {
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = useCallback((evt, { photo, index }) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  return (
    <Main className='short' isMobile={ isMobile }>
      <MetaTag>    
        <title>THE - { member.name }</title>
        <meta name='description' content={ `Détail de l'entreprise ${ member.name } membre de l'association.` } />
      </MetaTag>
      <div className='main-wrapper'>
        <Link className='close-btn' to={{ pathname: '/membres', state: { currentPage: 2 } } }><X /></Link>
        <div className='grid'>
          <div className='row'>
            <div className='col-xs4-4'>
              <h1>{ member.name }</h1>
            </div>
          </div>
          <div className='row middle-xs middle-md' style={{ marginBottom: '30px' }}>
            <div className='col-xs4-4 col-md-6'>
              <MemberCard member={ member } isDetail={ true } />              
            </div>
            <div className='col-xs4-4 col-md-6'>
              <div className='member-engagements'>
                { member.Engagements }
              </div>
            </div>            
          </div>
          <div className='row'>
            <div className='col-xs4-4 col-md-6'>
              <div className='member-intro'>
                { member.Intro }
              </div>
            </div>
            <div className='col-xs4-4 col-md-6'>
              <div className='member-content'>
                { member.Content }
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-xs4-4'>
              <Gallery photos={ member.photos } onClick={ openLightbox } />
              <ModalGateway>
              { viewerIsOpen ? (
                <Modal onClose={ closeLightbox }>
                  <Carousel currentIndex={ currentImage } views={ member.photos } />
                </Modal>
              ) : null }
              </ModalGateway>
            </div>
          </div>
        </div>
      </div>
    </Main>
  )
}

const members = [{
  name: 'Ascendense architecture',
  people: 'Laurent Bonne',
  address1: '3 chemin des oiseaux',
  address2: '88000 Gérardmer',
  phone: '09 64 05 59 33',
  email: 'bonne.ascendense@orange.fr',
  url: 'ascensense-architecture',
  Intro: (
    <ul>
      <li>Membre du Réseau Français de la Construction Paille (RFCP, les Compaillons)</li>
      <li>Acteurs de la Transition énergétique et écologique locale</li>
      <li>Recherche de solution contre les pathologies du bâti ancien</li>
      <li>Adepte de l’économie locale et circuits courts</li>
    </ul>
  ),
  Engagements: (
    <ul>
      <li>Architecture écologique biosourcée</li>
      <li>Rénovation patrimoniale et énergétique</li>
      <li>Formateur en éco-rénovation et construction paille</li>
    </ul>
  ),
  Content: (
    <div>
      <p>
        Ascendense architecture a été créée en janvier 2009 afin de mettre en
        place une structure professionnelle capable d'apporter des réponses et
        une méthodologie face aux enjeux environnementaux et au changement
        climatique.
      </p>
      <p>
        Nos préoccupations sont multiples et notre approche pluridisciplinaire et
        transversale. Pour nous, l'acte de construire est plus que la réalisation d'un
        bâtiment. Construire, c'est créer son cadre de vie, réaliser son rêve, comprendre 
        le lieu, son histoire et son environnement naturel et urbain, anticiper les 
        changements de société et la disponibilité des ressources énergétiques 
        d'aujourd'hui et de demain, partager une expérience profonde, construire avec le 
        vivant un environnement sain et pérenne, .... et rendre possible le monde de demain.
      </p>
      <p>
        Implanté dans le massif des Vosges, ascendense architecture travaille à
        l'échelle locale pour les collectivités, bailleur social et les privés. Nous
        sommes spécialisés dans les projets à caractère écologique de rénovation
        du patrimoine local et de constructions neuves très économes en énergie,
        l'accompagnement pédagogique des groupes en auto-promotion, la communication 
        des enjeux environnementaux auprès des habitants et collectivités locales via 
        des conférences, débats et ateliers de travail citoyens.
      </p>
    </div>
  ),
  profil: require('../media/members/ascendense-architecture/0-profil.png'),
  photos: [
    { src: require('../media/members/ascendense-architecture/1-logements-passif-beaune.jpg'), width: 4, height: 3 },
    { src: require('../media/members/ascendense-architecture/2-laurent-bonne.jpg'), width: 4, height: 3 },
    { src: require('../media/members/ascendense-architecture/3-vue-de-nuit.jpg'), width: 4, height: 3 },
    { src: require('../media/members/ascendense-architecture/4-voeux-ascendense-2011.jpg'), width: 4, height: 3 },
    { src: require('../media/members/ascendense-architecture/5-laurent-bonne.jpg'), width: 4, height: 3 },
    { src: require('../media/members/ascendense-architecture/6-maison-des-oiseaux.jpg'), width: 4, height: 3 }
  ]
},{
  name: 'Baradel paysage',
  people: 'Régis Baradel',
  address1: '711 chemin de la Reine',
  address2: '88100 Sainte-Margueritte',
  phone: '03 29 56 20 95',
  email: 'contact@baradel-paysage.com',
  url: 'baradel-paysage',
  Intro: (
    <ul>
      <li>Entreprise locale expérimentée et engagée dans l’approche écologique de l’habitat et de l’aménagement paysager</li>
      <li>Accompagnement de projet dans son intégration paysagère</li>
      <li>Formation aux bonnes pratiques environnementales</li>
      <li>Développement de tout type d’éco-solutions à destination des particuliers, professionnels et collectivité.</li>
    </ul>
  ),
  Engagements: (
    <div className='custom-grid'>
      <ul>
        <li>Revêtements drainants</li>
        <li>Toitures et murs végétalisés</li>
        <li>Installations récupératrices d’eaux de pluie</li>
        <li>Phyto-épuration</li>
        <li>Piscines biologiques</li>
      </ul>
      <ul>
        <li>Entretien d’espaces verts et potagers en gestion raisonnée</li>      
        <li>Agencements bois (terrasses durables en bois thermo chauffé)</li>
        <li>Compositions végétales réalisées avec des plantes rustiques et locales permettant de respecter et favoriser la biodiversité.</li>
      </ul>
    </div>
  ),
  Content: (
    <div>
      <p>
        Nous vivons avec la nature au quotidien avec le 
        souci constant de la respecter.
      </p>
      <p>
        <b>Pourquoi est-on membre de THE ?</b><br />
        Nous souhaitons nous positionner davantage dans 
        le domaine des éco-solutions, car il nous revient 
        en tant que paysagiste d’être force de propositions 
        sur tous les projets qui touchent à l’environnement.
      </p>
      <p>
        Vous voulez des idées tendances et novatrices,
        être convaincu de notre créativité et de la 
        qualité de nos prestations.<br />
        Une solution : venez découvrir en exclusivité 
        notre nouveau showroom ! Au plaisir de vous 
        conseiller, vous accompagner avec notre bureau
        d’études, pour la réalisation de vos projets 
        d’agencements et d’aménagements. Venez nous 
        retrouver sur différents salons localisés à 
        Épinal, Gérardmer, Moyenmoutier et 
        Saint-Dié-des-Vosges !
      </p>
    </div>
  ),
  profil: require('../media/members/baradel-paysage/0-profil.png'),
  photos: [
    { src: require('../media/members/baradel-paysage/2-baradel-paysage.jpg'), width: 4, height: 3 },
    { src: require('../media/members/baradel-paysage/3-baradel-paysage.jpg'), width: 4, height: 3 },
    { src: require('../media/members/baradel-paysage/1-baradel-paysage.jpg'), width: 3, height: 4 },
    { src: require('../media/members/baradel-paysage/4-baradel-paysage.jpg'), width: 3, height: 4 },
    { src: require('../media/members/baradel-paysage/5-baradel-paysage.jpg'), width: 4, height: 3 },
    { src: require('../media/members/baradel-paysage/6-baradel-paysage.jpg'), width: 4, height: 3 },
  ]
},{
  name: 'Charpente Schoener & fils',
  people: 'Joël Schoener',
  address1: '108 rue de Stalingrad',
  address2: '88110 Raon l\'étape',
  phone: '03 29 56 20 95',
  email: 'sarl.schoener@gmail.com',
  url: 'charpente-schoener-et-fils',
  Intro: (
    <ul>
      <li>Gérant de l'entreprise labellisée Maître artisan charpentier et éco-artisan</li>
      <li>Entreprise certifiée RGE Qualibat</li>
      <li>Savoir-faire inégalé dans les travaux d’isolation intérieure et extérieure</li>
    </ul>
  ),
  Engagements: (
    <div className='custom-grid'>
      <ul>
        <li>Isolation</li>
        <li>Bardage</li>
        <li>Charpente</li>
      </ul>
      <ul>
        <li>Couverture</li>
        <li>Zinguerie</li>
      </ul>
    </div>
  ),
  Content: (
    <div>
      <p>
        Afin de réussir votre chantier dans les règles 
        de l’art, nous mettons à votre disposition des 
        matériaux haut de gamme.<br />
        Pour votre confort au quotidien, nous vous 
        accompagnons dans votre projet et nous vous 
        proposons des solutions étudiées et bien adaptées 
        à votre besoin. Possédant diverses certifications, 
        notre entreprise vous garantit des prestations de 
        qualité eectuées dans le respect des normes en
        vigueur et dans les plus brefs délais.
      </p>
    </div>
  ),
  profil: require('../media/members/charpente-schoener-et-fils/0-profil.png'),
  photos: [
    { src: require('../media/members/charpente-schoener-et-fils/1-book.jpg'), width: 4, height: 3 },
    { src: require('../media/members/charpente-schoener-et-fils/2-isolation.png'), width: 4, height: 3 },
    { src: require('../media/members/charpente-schoener-et-fils/3-toiture.jpg'), width: 4, height: 3 },
    { src: require('../media/members/charpente-schoener-et-fils/4-chantier.jpg'), width: 4, height: 3 },
    { src: require('../media/members/charpente-schoener-et-fils/5-charpente-schoener.jpg'), width: 4, height: 3 },
    { src: require('../media/members/charpente-schoener-et-fils/6-charpente-schoener.jpg'), width: 4, height: 3 }
  ]
},{
  name: 'Fleur de chaux',
  people: 'Philippe Kirscher',
  address1: '4b rue des fabriques',
  address2: '68470 Fellering',
  phone: '06 73 38 43 64',
  email: 'contact@fleur-de-chaux.com',
  url: 'fleur-de-chaux',
  Intro: (
    <ul>
      <li>Formations sur mesure à toutes techniques</li>
      <li>Démarrage de chantier</li>
      <li>Animation torchis</li>
    </ul>
  ),
  Engagements: (
    <div className='custom-grid'>
      <ul>
        <li>Enduits naturels</li>
        <li>Chaux</li>
        <li>Pigments</li>
        <li>Ocres</li>
        <li>Argile</li>
      </ul>
      <ul>
        <li>Tadelakt</li>
        <li>Chaux chanvre</li>
        <li>Peinture à l'huile de lin</li>
        <li>Torchis</li>
        <li>Colombages</li>
      </ul>
    </div>
  ),
  Content: (
    <div>
      <p>
        Promouvoir la construction écologique ainsi que
        les pratiques vernaculaires afin de préserver 
        et d’utiliser le bâti ancien.
      </p>
    </div>
  ),
  profil: require('../media/members/fleur-de-chaux/0-profil.png'),
  photos: [
    { src: require('../media/members/fleur-de-chaux/1-fleur-de-chaux.jpg'), width: 4, height: 3 },
    { src: require('../media/members/fleur-de-chaux/2-fleur-de-chaux.png'), width: 3, height: 4 },
    { src: require('../media/members/fleur-de-chaux/3-fleur-de-chaux.jpg'), width: 4, height: 3 },
    { src: require('../media/members/fleur-de-chaux/4-fleur-de-chaux.jpg'), width: 3, height: 4 },
    { src: require('../media/members/fleur-de-chaux/5-fleur-de-chaux.png'), width: 4, height: 3 },
    { src: require('../media/members/fleur-de-chaux/6-fleur-de-chaux.jpg'), width: 3, height: 4 }
  ]
},{
  name: 'Isoleco',
  people: 'Damien Villaume',
  address1: '5b rue de Galilée',
  address2: '88520 Raves',
  phone: '03 29 55 12 48',
  email: 'contact@isoleco.fr',
  url: 'isoleco',
  Intro: (
    <p>
      Créée en 1979 à Saint-Dié (Vosges), au lendemain du 
      premier choc pétrolier, ISOLECO est, dès 1980, le premier
      applicateur de ouate de cellulose en France.<br />
      Devenu expert en isolation à base de produits préservant 
      l’environnement, ISOLECO propose des solutions innovantes.<br />
      Isoleco se spécialise dans la rénovation du bâti ancien.
    </p>
  ),
  Engagements: (
    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '32px' }}>
      <ul>
        <li>Finition enduit ou bardée</li>
        <li>Isolation extérieure</li>
        <li>Plâtrerie</li>
        <li>Menuiseries</li>
        <li>Pose de parquets</li>
        <li>Isolation intérieure avec aménagements</li>
        <li>Isolation toiture méthode sarking</li>
      </ul>
      <ul>
        <li>Traitement des remontées capillaires</li>
        <li>Traitement de charpentes</li>
        <li>Traitement de toitures</li>
        <li>Traitement des champignions lignivores</li>
        <li>Ventilation</li>
        <li>Spécialisation en rénovation du bâti ancien</li>
      </ul>
    </div>
  ),
  Content: (
    <div>
      <p>
        L'homme puise son énergie dans 4 éléments majeurs de 
        la biosphère : l'air, l'eau, la terre ou les minéraux, 
        le feu ou champs et rayonnements naturels.
      </p>
      <p>
        Une construction écologique doit être perméable à ces 
        4 éléments.<br />
        Notre corps est sensible à ces 4 éléments.
        Par exemple notre peau respire et transpire. 
        Instinctivement nous choisirons des tenues ayant le 
        même fonctionnement hygrométrique.
        L'habitat est notre troisième peau après nos 
        vêtements.
      </p>
      <p>
          Pour notre habitat Isoleco a des solutions qui 
          respectent les échanges face à ces 4 éléments.
      </p>
      <p>
        Grâce aux matériaux utilisés, Isoleco préserve le 
        confort de l’habitant et de l’habitat.
        Depuis toujours, Isoleco a fait le choix d’utiliser 
        des matériaux bio-sourcés pour l’isolation, produits 
        base végétale comme la ouate de cellulose, la laine 
        de bois.... Même pour les prestations de traitements, 
        Isoleco utilise des matériaux naturels 
        (minéralisation des supports).
      </p>
    </div>
  ),
  profil: require('../media/members/isoleco/0-profil.png'),
  photos: [
    { src: require('../media/members/isoleco/1-iti.jpg'), width: 4, height: 3 },
    { src: require('../media/members/isoleco/2-humidite.jpg'), width: 4, height: 3 },
    { src: require('../media/members/isoleco/3-isolation-thermique.jpg'), width: 4, height: 3 },
    { src: require('../media/members/isoleco/4-isolation-thermique.jpg'), width: 4, height: 3 },
    { src: require('../media/members/isoleco/5-isolation-thermique.jpg'), width: 4, height: 3 },
    { src: require('../media/members/isoleco/6-ite.jpg'), width: 4, height: 3 }
  ]
},{
  name: 'J.L. Thomas architecte',
  people: 'Jean-Luc Thomas',
  address1: '384 la place',
  address2: '68910 Labaroche',
  phone: '09 89 49 64 49',
  email: 'jean-luc.thomas15@orange.fr',
  url: 'jean-luc-thomas-architecte',
  Intro: (
    <p>
      Fondateur de “Association Habitat Sain“ en 1988
      afin de promouvoir la qualité des matériaux et des 
      technologies pour préserver la qualité de vie 
      dans les bâtiments.<br />
      Réalisation de tout type de bâtiments en optimisant 
      les matériaux biosourcés.<br />
      Initiateur de la construction en paille depuis 1985.<br />
      Réhabilitation énergétique de bâtiments anciens.
    </p>
  ),
  Engagements: (
    <ul>
      <li>Conception et réalisation</li>
      <li>Bâtiments neufs ou anciens</li>
      <li>Basse consommation</li>
      <li>Formateur DORéMI</li>
      <li>Formateur Oktave</li>
      <li>Formateur PRAXIBAT</li>
    </ul>
  ),
  Content: (
    <div>
      <p>
        Dans l’esprit de la “Transition écologique“, Expliquer 
        et comprendre. Echanger avec les corps de métiers. 
        Faire évoluer les connaissances, ceci permettant de 
        faire “monter“ le niveau naturel d’exigence et de 
        performance des intervenants dans l’art de construire.
      </p>
      <p>
        S’ouvrir et communiquer avec tout maître d’ouvrage
        potentiel pour partager et harmoniser les savoir-faire,
        expliquer les coûts de prestation.
      </p>
      <p>
        Communiquer avec le grand public sur l’évolution 
        générale des règles et techniques de construction 
        et en particulier concernant la rénovation énergétique.
      </p>
      <p>
        Proposer des actions concrètes de solutions 
        d’autoconstruction qui fassent référence aux bonnes 
        pratiques professionnelles.
      </p>
    </div>
  ),
  profil: require('../media/members/jean-luc-thomas-architecte/0-profil.png'),
  photos: [
    { src: require('../media/members/jean-luc-thomas-architecte/1-les-forges.jpg'), width: 4, height: 3 },
    { src: require('../media/members/jean-luc-thomas-architecte/2-les-forges.jpg'), width: 4, height: 3 },
    { src: require('../media/members/jean-luc-thomas-architecte/3-jean-luc-thomas-architecte.jpg'), width: 4, height: 3 },
    { src: require('../media/members/jean-luc-thomas-architecte/4-katzenthal-interieur-fini.jpg'), width: 4, height: 3 },
    { src: require('../media/members/jean-luc-thomas-architecte/5-facade.jpg'), width: 4, height: 3 },
    { src: require('../media/members/jean-luc-thomas-architecte/6-jean-luc-thomas-architecte.jpg'), width: 3, height: 4 }
  ]
},{
  name: 'LORE - Atelier d\'Architecture',
  people: 'Matthias Knoblauch',
  address1: '13 rue de Pfastatt - Bâtiment 75',
  address2: '68200 Mulhouse',
  phone: '07 68 04 12 01',
  email: 'loreatelierdarchitecture@gmail.com',
  url: 'lore-atelier-architecture',
  Intro: (
    <ul>
      <li>Architecte HMO NP</li>
      <li>Membre de la Frugalité Heureuse et Créative</li>
      <li>Spécialiste dans la construction bois</li>
    </ul>
  ),
  Engagements: (
    <ul>
      <li>Conception et réalisation d’architecture située bâti ancien et existant / neuf</li>
      <li>Laboratoire d’expérimentation architecturale installation / scénographie</li>
      <li>Pédagogie sur les modes de construire</li>
    </ul>
  ),
  Content: (
    <div>
      <p>
        LORE signifie l’ensemble des savoirs, des 
        savoir-faire et des traditions transmis de 
        bouche à oreille.
      </p>
      <p>
      L’atelier est installé à MoToCo (Mulhouse), 
      dans une marmite bouillonnante de créativité. 
      À l’heure où la société se réinvente dans le 
      désordre, entre l’urgence climatique et la frénésie 
      de la production mondialisée, cette oasis de 
      culture permet de ré-enchanter les avenirs 
      possibles. La démarche se veut ainsi co-créative, 
      défendant les notions d’usage partagé, de 
      mutualisation et de frugalité. Pour une architecture 
      sensuelle, résiliante et autonome, au service de la 
      culture locale.
      </p>
    </div>
  ),
  profil: require('../media/members/lore-atelier-architecture/0-profil.png'),
  photos: [
    { src: require('../media/members/lore-atelier-architecture/1-jeubeuj.jpg'), width: 4, height: 3 },
    { src: require('../media/members/lore-atelier-architecture/2-jeubeuj.jpg'), width: 4, height: 3 },
    { src: require('../media/members/lore-atelier-architecture/3-lore-architecture.jpg'), width: 4, height: 3 },
    { src: require('../media/members/lore-atelier-architecture/4-lore-architecture.jpg'), width: 4, height: 3 },
    { src: require('../media/members/lore-atelier-architecture/5-lore-architecture.jpg'), width: 4, height: 3 },
    { src: require('../media/members/lore-atelier-architecture/6-mobilier-bois.jpg'), width: 4, height: 3 },
    { src: require('../media/members/lore-atelier-architecture/7-lore-architecture.jpg'), width: 4, height: 3 }
  ]
},{
  name: 'Menuiserie Thirion',
  people: 'Denis Thirion',
  address1: '80ter avenue du Général de Gaulle',
  address2: '88420 Moyenmoutier',
  phone: '03 29 41 92 56',
  email: 'menuiserie.thirion@orange.fr',
  url: 'menuiserie-thirion',
  Intro: (
    <p>
      La menuiserie THIRION est une entreprise familiale de père
      en fils créée en 1955. Elle est implantée en plein coeur des
      Vosges, à proximité de Saint-Dié des Vosges. L'entreprise est
      spécialisée dans la fabrication et la rénovation de toutes les
      menuiseries intérieures et extérieures.<br />
      Agrément "Eco Artisan" Depuis 2010, engagés dans
      l’amélioration des performances énergétiques et au respect
      de l’environnement.
    </p>
  ),
  Engagements: (
    <ul>
      <li>Menuiseries intérieures et extérieures</li>
      <li>Fourniture et Pose</li>
      <li>Conseils pour une bonne pratique en éco-rénovation</li>
    </ul>
  ),
  Content: (
    <div></div>
  ),
  profil: require('../media/members/menuiserie-thirion/0-profil.png'),
  photos: [
    { src: require('../media/members/menuiserie-thirion/1-ets.jpg'), width: 3, height: 4 },
    { src: require('../media/members/menuiserie-thirion/2-thirion-porte.jpg'), width: 4, height: 3 },
    { src: require('../media/members/menuiserie-thirion/3-thirion porte.jpg'), width: 3, height: 4 },
    { src: require('../media/members/menuiserie-thirion/4-thirion-denis.jpg'), width: 3, height: 4 },
    { src: require('../media/members/menuiserie-thirion/5-thirion-porte.jpg'), width: 3, height: 4 }
  ]
},{
  name: 'Panoptique',
  people: 'François Liermann',
  address1: '5 rue des veaux',
  address2: '67000 Strasbourg',
  phone: '03 88 41 18 61',
  email: 'info@panoptique.net',
  url: 'panoptique',
  Intro: (
    <ul>
      <li>Réhabilitation bâti ancien et existant</li>
      <li>Rénovation énergétique globale et performante</li>
      <li>Bâtiment neuf écologique</li>
      <li>Physique des matériaux (vapeur d’eau, confort...)</li>
      <li>Formateur Négawatt, enseignant ENSAS</li>
      <li>Formateur Oktave</li>
    </ul>
  ),
  Engagements: (
    <ul>
      <li>Architecture</li>
      <li>Assistance à maîtrise d’ouvrage QEB</li>
      <li>Formation démarches QEB et écoconception</li>
    </ul>
  ),
  Content: (
    <div>
      <p>
        Depuis 20 ans, l’entreprise approfondi et partage 
        avec le plus grand nombre d’acteurs possibles 
        (maitres d’ouvrages publics et privés, élus,
        professionnels, associations, étudiants, artisans) 
        la sensibilité et l’action de promotion du bâtiment 
        respectueux de l’environnement, de la construction 
        ou de l’acte de construire « juste » au regard des 
        enjeux de notre siècle.
      </p>
      <p>
        Elle cumule les compétences d’architecture et 
        d’ingénierie thermique.<br />
        Le réseau THE entre dans cette dynamique et permet à 
        ses différents acteurs de mieux se compléter et se 
        comprendre sur un territoire homogène.<br />
        Leur action en est amplifiée pour une meilleure 
        perception des interlocuteurs (grand public, élus, 
        confrères etc.) Panoptique organise et anime les 
        « couarôges », stammtisch / tables rondes mensuelles 
        avec le grand public et participe à la dynamisation du 
        site du Pôle d’Eco-Construction des Vosges (PECV) de Fraize.
      </p>
    </div>
  ),
  profil: require('../media/members/panoptique/0-profil.png'),
  photos: [
    { src: require('../media/members/panoptique/1-panoptique.jpg'), width: 4, height: 3 },
    { src: require('../media/members/panoptique/2-panoptique.jpg'), width: 4, height: 3 },
    { src: require('../media/members/panoptique/4-logo-panoptique.jpg'), width: 4, height: 3 },
    { src: require('../media/members/panoptique/5-panoptique.jpg'), width: 4, height: 3 },
    { src: require('../media/members/panoptique/6-panoptique.jpg'), width: 3, height: 4 }
  ]
},{
  name: 'Thermair',
  people: 'Cédric Rosa',
  address1: '13 rue Bel Air',
  address2: '88600 Bruyères',
  phone: '06 18 45 36 73',
  email: 'cedric@thermair.fr',
  url: 'thermair',
  Intro: (
    <ul>
      <li>Bureau d'études</li>
      <li>Assistance à maîtrise d'ouvrage, conception, contrôle, étude de faisabilité, maîtrise d'oeuvre, programmation</li>
      <li>Label LQE 2014-2018</li>
    </ul>
  ),
  Engagements: (
    <ul>
      <li>Thermique</li>
      <li>Fluide</li>
      <li>Qualité de l'air</li>
      <li>Ventilation</li>
    </ul>
  ),
  Content: (
    <div></div>
  ),
  profil: require('../media/members/thermair/0-profil.png'),
  photos: [
    { src: require('../media/members/thermair/1-menuiserie-et-ite.jpg'), width: 4, height: 3 },
    { src: require('../media/members/thermair/2-baie-vitree-avec-bso.jpg'), width: 3, height: 4 },
    { src: require('../media/members/thermair/3-vue-aerienne.jpg'), width: 4, height: 3 },
    { src: require('../media/members/thermair/4-thermair.jpg'), width: 4, height: 3 }
  ]
}]

const Main = styled.main`
${ p => p.isMobile && `margin-top: 70px;` }
.custom-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 32px;
  align-items: center;
}
.member-intro {
  font-size: 20px;
  font-weight: 300;
  font-style: italic;

  p {
    margin-top: 0;
  }

  ul {
    list-style-type: none;
    margin-top: 0;
    padding: 0;
  }
}
.member-content {
  p:first-child {
    margin-top: 0;
  }
}
.member-engagements {
  ul {
    list-style-type: none;
    padding: 0;
    margin-bottom: 16px;
    text-align: center;

    li {
      //font-weight: bold;
      font-size: 18px;
      line-height: 20px;
      padding: 8px 0;
    }
  }
}
`