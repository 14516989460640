import React from 'react'
import styled from 'styled-components'

import logoImg from '../../media/layout/the-logo.svg'

const Logo = ({ isMobile }) => {
  return isMobile ? (
    <MobileLogo>
      <img src={ logoImg } title='Logo THE' alt="Logo de l'association THE" />
    </MobileLogo>
  ) : (
    <DesktopLogo src={ logoImg } title='Logo THE' alt="Logo de l'association THE" />
  )
}

export default Logo

const DesktopLogo = styled.img`
position: fixed;
top: 16px;
left: 8px;
width: 15vw;
min-width: 120px;
max-width: 180px;
`
const MobileLogo = styled.div`
background-color: white;
position: fixed;
left: 8px;
top: 0;
right: 0;
z-index: 3;

img {
width: 100px;
margin-left: 10vw;
}
`