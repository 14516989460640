import React, { useState } from 'react'
import useTornis from 'react-use-tornis'
import styled from 'styled-components'
import MetaTag from 'react-meta-tags'

const Contact = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600)

  useTornis(({ size }) => {
    if (size.x < 600 && isMobile === false)
      setIsMobile(true)
    else if (size.x >= 600 && isMobile === true)
      setIsMobile(false)
  }, true, ['size'])

  return (
    <Main isMobile={ isMobile }>
      <MetaTag>    
        <title>THE - Contact</title>
        <meta name='description' content="Contactez l'association Transition Habitat Environnement." />
      </MetaTag>
      <div className='main-wrapper'>
        <div className='grid'>
          <div className='row'>
            <div className='col-xs4-4'>
              <h1>Contact</h1>
            </div>
            <div className='col-xs4-4 col-sm8-4'>
              <form>
                <div className='form-group'>
                  <input type='email' placeholder='Email' />
                </div>
                <div className='form-group'>
                  <textarea placeholder='Votre message' rows={5}></textarea>
                </div>
                <div className='form-group'>
                  <button type='submit'>Envoyer</button>
                </div>
              </form>
            </div>
            <div className='col-xs4-4 col-sm8-4'>
              <iframe 
                title='Google Map' 
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1329.9796236461937!2d6.992185558275167!3d48.188136683001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4793e82039ef38ed%3A0x61fa330bce7b2e93!2sP%C3%B4le+Ecoconstruction+des+Vosges+-+PECV!5e0!3m2!1sfr!2sfr!4v1556889718715!5m2!1sfr!2sfr" 
                frameBorder="0" 
                allowFullScreen></iframe>
            </div>
          </div>
        </div>
      </div>
    </Main>
  )
}

export default Contact

const Main = styled.main`
${ p => p.isMobile && `margin-top: 70px;` }
form {
  .form-group {
    margin-bottom: 8px;
    input, textarea {
      width: 100%;
      max-width: 100%
      min-width: 100%;
      padding: 8px;
      box-sizing: border-box;
    }
    button {
      display: block;
      margin-left: auto;
      text-transform: uppercase;
      padding: 8px 12px;
      background-color: #C0444F;
      color: white;
      border: none;
      border-radius: 3px;
      cursor: pointer;
    }
  }
}

iframe {  
  width: 100%;
  height: 50vh;
}
`