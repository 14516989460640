import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import useTornis from 'react-use-tornis'
import styled from 'styled-components'
import { Phone, Mail } from 'react-feather'

const MemberCard = ({ member, isDetail }) => {
  const history = useHistory()
  const [isMobile, setIsMobile] = useState(window.innerWidth < 400)

  useTornis(({ size }) => {
    if (size.x < 400 && isMobile === false)
      setIsMobile(true)
    else if (size.x >= 400 && isMobile === true)
      setIsMobile(false)
  }, true, ['size'])

  const linkTo = { 
    pathname: '/membres/' + member.url, 
    state: { 
      currentPage: 2, 
      sub: true 
    } 
  }

  const onClick = isDetail ? null : evt => {
    if (evt.target.tagName.toLowerCase() === 'a')
      return 
    history.push('/membres/' + member.url, { 
      currentPage: 2, 
      sub: true 
    })
  }

  return isMobile ? (
    <ContainerMobile onClick={ onClick } enableCursor={ onClick !== null }>
      <h2>{ !isDetail ? member.name : 'Contact' }</h2>
      <img src={ member.profil } alt={ 'Profil ' + member.name } />
      <span className='member-people'>{ member.people }</span>
      <span className='member-info'>{ member.address1 }<br/>{ member.address2 }</span>
      <span className='member-info'><a href={ 'tel:' + member.phone }>{ member.phone }</a></span>
      <span className='member-info'><a href={ 'mailto:' + member.email }>{ member.email }</a></span>
    </ContainerMobile>
  ) : (
    <Container onClick={ onClick } enableCursor={ onClick !== null }>
      <div className='member-avatar'>
        <img src={ member.profil } alt={ 'Profil ' + member.name } />
      </div>
      <div className='member-body'>
        <h2>{ !isDetail ? member.name : 'Contact' }</h2>
        <span className='member-people'>{ member.people }</span>
        <div className='member-address'>
          <span>{ member.address1 }<br/>{ member.address2 }</span>
        </div>
        <div className='member-phone'>
          <span><Phone size={ 14 } /><a href={ 'tel:' + member.phone }>{ member.phone }</a></span>
        </div>
        <div className='member-email'>
          <span><Mail size={ 14 } /><a href={ 'mailto:' + member.email }>{ member.email }</a></span>
        </div>
      </div>
    </Container>
  )
}

export default MemberCard

const ContainerMobile = styled.div`
box-shadow: rgba(0    , 0, 0, 0.2) 0px 1px 8px 0px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 3px 3px -2px;
background-color: white;
margin-bottom: 16px;
padding: 8px;
transition: box-shadow 250ms ease, background-color 250ms ease;
${ p => p.enableCursor && `
cursor: pointer;
&:hover {
  background-color: white;
  box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
}
` }

h2 {
  font-weight: bold;
  text-align: center;
  font-size: 18px;
  text-transform: uppercase;
  margin: 0;
}
img {
  display: block;
  margin: auto;
  padding: 8px 0;
}
a {
  color: rgb(25,25,25);
}
.member-people {
  font-size: 16px;
}
.member-info {
  display: block;
  margin: 8px 0;
  font-size: 12px;
}
.member-detail-btn {
  display: block;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  padding: 8px;
  margin: 0;
  border-radius: 2px;
  transition: background-color 250ms ease;
  background-color: rgba(0,0,0,0.1);
}
`

const Container = styled.div`
box-shadow: rgba(0    , 0, 0, 0.2) 0px 1px 8px 0px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 3px 3px -2px;
background-color: #F4F4F4;
margin-bottom: 16px;
display: grid;
grid-template-columns: auto 1fr;
transition: box-shadow 250ms ease, background-color 250ms ease;
${ p => p.enableCursor && `
cursor: pointer;
&:hover {
  background-color: white;
  box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
}
` }

h2 {
  font-weight: bold;
  text-align: left;
  margin: 0;
  font-size: 20px;
  text-transform: uppercase;
}

a {
  color: rgb(25,25,25);
}

.member-avatar {
  margin: 4px;
  width: 100px;
  height: 170px;
}

.member-body {
  position: relative;
  padding: 8px 4px;
  
  .member-people {
    font-size: 16px;
  }
  .member-address {
    margin: 8px 0;
    font-size: 14px;
  }
  .member-phone span,
  .member-email span {
    svg {
      margin-right: 4px;
    }
    font-size: 14px;
    display: flex;
    align-items: center;
  }
  .member-detail-btn {
    position: absolute;
    right: 8px;
    bottom: 8px;
    text-transform: uppercase;
    text-decoration: none;
    padding: 8px;
    border-radius: 2px;
    transition: background-color 250ms ease;
    &:hover {        
      background-color: rgba(0,0,0,0.1);
    }
  }
}
`